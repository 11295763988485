<template>
  <div>
    <Menu :lang="lang" />
    <section class="w-screen min-h-screen bg-white" v-if="abogado._id">
      <div class="grid grid-cols-1 md:grid-cols-3">
        <div class="grid grid-cols-1 place-content-center">
          <p class="text-right">
            <img
              :src="cdn + abogado.imagen"
              class="lg:max-5/6 inline-block md:pl-10"
            />
          </p>
          <div
            class="w-full h-10 bg-white grid grid-cols-6 place-content-center"
          >
            <div class="bg-slate-200 md:bg-white"></div>
            <div class="h-10 col-span-5 bg-verde"></div>
          </div>
        </div>
        <div
          class="
            bg-slate-200
            font-serif
            min-h-screen
            py-20
            px-8
            lg:px-20
            md:col-span-2
            grid grid-cols-1
            place-content-evenly
          "
        >
          <div class="lg:max-5/6">
            <h1 class="text-xl">{{ abogado.nombre }}</h1>
            <h2 class="text-lg" v-if="abogado.titulo">{{ abogado.titulo }}</h2>
            <h2 class="text-lg">{{ abogado.puesto }}</h2>
            <p>&nbsp;</p>
            <p v-if="abogado.email && abogado.email != ''">
              Email<br /><a :href="'mailto:' + abogado.email">{{
                abogado.email
              }}</a>
            </p>
            <p v-if="abogado.telefono && abogado.telefono != ''">
              <span v-if="lang == 'es'">Teléfono</span
              ><span v-if="lang == 'en'">Phone</span><br /><a
                :href="'tel:' + abogado.telefono.replace(/ /g, '')"
                >{{ abogado.telefono }}</a
              >
            </p>
            <p v-if="abogado.vcard && abogado.vcard != ''">
              <a target="_blank" :href="cdn + vcard">Vcard</a>
            </p>
            <p
              class="my-4 text-justify"
              v-html="abogado['descripcion_' + lang] || abogado.descripcion"
            ></p>
          </div>
        </div>
      </div>
    </section>
    <Footer :lang="lang" :mostrarDireccion="false" />
  </div>
</template>
<script>
import Api from "@/components/Api";
import Menu from "./Menu.vue";
import Footer from "./Footer.vue";
export default {
  name: "Abogado",
  components: {
    Menu,
    Footer,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cdn: Api.cdn,
      abogado: {},
      lang: localStorage.getItem("lang") || "es",
    };
  },
  async mounted() {
    const abogados = await Api.readBypass("abogadopublic");
    for (let a of abogados) if (a._id == this.id) this.abogado = a;
    console.log(this.abogado.descripcion);
    this.abogado.descripcion = this.abogado.descripcion.replace(
      /\n/g,
      "<br />"
    );
    if (!(this.abogado.telefono && this.abogado.telefono != ""))
      this.abogado.telefono = "444 811 8193";
  },
};
</script>
