<template>
  <div>
    <Menu :lang="lang" />
    <section class="w-screen min-h-screen grid grid-cols-1 md:grid-cols-2">
      <div
        class="
          md:min-h-screen
          bg-white
          grid grid-cols-1
          place-content-center
          py-4
          bg-cover bg-center bg-[url('1x/edificios.png')]
        "
      >
        <p class="text-center p-16 md:p-0">
          <img src="elite/SVG/completo.svg" class="w-1/2 inline-block" />
        </p>
      </div>
      <div
        class="
          md:min-h-screen
          overflow-auto
          bg-verde
          grid grid-cols-1
          place-content-center
          p-12
          text-white
          uppercase
        "
      >
        <h1 class="py-12 md:py-6 text-xl" v-if="lang == 'es'">
          Conoce a nuestros <strong>abogados</strong>
        </h1>
        <h1 class="py-12 md:py-6 text-xl" v-if="lang == 'en'">
          Our <strong>lawyers</strong>
        </h1>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 place-content-center">
          <a
            v-for="a in abogados"
            :key="a._id"
            class="abogado relative h-72 rounded-lg z-10 shadow-md"
            :href="'#/abogados/' + a._id"
          >
            <p class="p-6 text-center absolute bottom-0 text-sm z-20">
              {{ a.nombre }}
            </p>
            <div
              class="h-72 bg-cover bg-center foto z-10"
              :class="'bg-[url(' + cdn + a.imagen + ')]'"
            ></div>
          </a>
        </div>
      </div>
    </section>
    <Footer :lang="lang" />
  </div>
</template>
<style scoped>
.abogado .foto {
  filter: brightness(0.75);
  z-index: -10;
}
.abogado p {
  text-shadow: 2px 2px 4px #222;
}
</style>
<script>
import Api from "@/components/Api";
import Menu from "./Menu.vue";
import Footer from "./Footer.vue";
export default {
  name: "Abogados",
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      cdn: Api.cdn,
      abogados: [],
    };
  },
  async mounted() {
    this.abogados = (await Api.readBypass("abogadopublic")).sort((a, b) =>
      a.cardinalidad > b.cardinalidad ? 1 : -1
    );
    console.log("Abogados", this.abogados.length);
  },
};
</script>
